.icon-404 {
    background: url("https://img2.cncenter.cz/images/abicko/dist/non-sprite/404.png") no-repeat;
    height: 206px;
    width: 520px;
}

.icon-ales_mourrison {
    background: url("https://img2.cncenter.cz/images/abicko/dist/non-sprite/ales_mourrison.png") no-repeat;
    height: 118px;
    width: 105px;
}

.icon-dzouky {
    background: url("https://img2.cncenter.cz/images/abicko/dist/non-sprite/dzouky.png") no-repeat;
    height: 53px;
    width: 166px;
}

.icon-banner {
    background: url("https://img2.cncenter.cz/images/abicko/dist/non-sprite/banner.png") no-repeat;
    height: 115px;
    width: 300px;
}

.icon-Mourrison {
    background: url("https://img2.cncenter.cz/images/abicko/dist/non-sprite/Mourrison.png") no-repeat;
    height: 114px;
    width: 131px;
}

.icon-honza_horcik {
    background: url("https://img2.cncenter.cz/images/abicko/dist/non-sprite/honza_horcik.jpg") no-repeat;
    height: 111px;
    width: 107px;
}

.icon-ladislav_andel {
    background: url("https://img2.cncenter.cz/images/abicko/dist/non-sprite/ladislav_andel.jpg") no-repeat;
    height: 119px;
    width: 107px;
}

.icon-letter {
    background: url("https://img2.cncenter.cz/images/abicko/dist/non-sprite/letter.png") no-repeat;
    height: 21px;
    width: 29px;
}

.icon-mourisson-dotaz {
    background: url("https://img2.cncenter.cz/images/abicko/dist/non-sprite/mourisson-dotaz.png") no-repeat;
    height: 169px;
    width: 149px;
}

.icon-mourrison_zasahuje {
    background: url("https://img2.cncenter.cz/images/abicko/dist/non-sprite/mourrison_zasahuje.png") no-repeat;
    height: 55px;
    width: 258px;
}

.icon-new-edition-background {
    background: url("https://img2.cncenter.cz/images/abicko/dist/non-sprite/new-edition-background.png") no-repeat;
    height: 300px;
    width: 300px;
}

.icon-pavel_novak {
    background: url("https://img2.cncenter.cz/images/abicko/dist/non-sprite/pavel_novak.jpg") no-repeat;
    height: 121px;
    width: 107px;
}

.icon-tukie {
    background: url("https://img2.cncenter.cz/images/abicko/dist/non-sprite/tukie.jpg") no-repeat;
    height: 119px;
    width: 107px;
}

.icon-foto-robot {
    background: url("https://img2.cncenter.cz/images/abicko/dist/non-sprite/festivalabc/foto-robot.png") no-repeat;
    height: 478px;
    width: 548px;
}

.icon-foto-idiot {
    background: url("https://img2.cncenter.cz/images/abicko/dist/non-sprite/festivalabc/foto-idiot.png") no-repeat;
    height: 463px;
    width: 592px;
}

.icon-foto-tyranosaurus {
    background: url("https://img2.cncenter.cz/images/abicko/dist/non-sprite/festivalabc/foto-tyranosaurus.png") no-repeat;
    height: 466px;
    width: 577px;
}

.icon-pozadi-mrak-modry {
    background: url("https://img2.cncenter.cz/images/abicko/dist/non-sprite/festivalabc/pozadi-mrak-modry.png") no-repeat;
    height: 597px;
    width: 1921px;
}

.icon-pozadi-mrak-ruzovy {
    background: url("https://img2.cncenter.cz/images/abicko/dist/non-sprite/festivalabc/pozadi-mrak-ruzovy.png") no-repeat;
    height: 597px;
    width: 1921px;
}

.icon-pozadi-mrak-bily {
    background: url("https://img2.cncenter.cz/images/abicko/dist/non-sprite/festivalabc/pozadi-mrak-bily.png") no-repeat;
    height: 597px;
    width: 1921px;
}

.icon-foto-vr {
    background: url("https://img2.cncenter.cz/images/abicko/dist/non-sprite/festivalabc/foto-vr.png") no-repeat;
    height: 438px;
    width: 602px;
}

.icon-sipka-dolu {
    background: url("https://img2.cncenter.cz/images/abicko/dist/non-sprite/festivalabc/sipka-dolu.png") no-repeat;
    height: 50px;
    width: 50px;
}
